import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { resetPassword } from '../helpers/auth';

const titleClassNames = ['bold', 'center', 'wcag-h2-mimic-h3'];

function ForgotPassword(props) {
  const { state = {} } = useLocation();
  const [email, setEmail] = useState(state.email || '');
  const [validEmail, setValidEmail] = useState(true);
  const [emailTouched, setEmailTouched] = useState(false);
  const history = useHistory();

  async function sendToEmail() {
    await resetPassword({ email, wrap_output: true });
    history.push('forgot-password-response', { email });
  }

  function handleFocus(e) {
    setEmailTouched(false);
  }

  function handleBlur(e) {
    const el = e.currentTarget;
    const emailValue = el.value;
    console.log('====> handling email blur', emailValue);
    setEmailTouched(true);
    if (isValidEmail(emailValue)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }

  }

  function handleChangeEmail(e) {
    const el = e.currentTarget;
    const emailValue = el.value;
    console.log('====> handleChangeEmail', emailValue);
    setEmail(emailValue);
  }

  const isValidEmail = (emailToCheck) => {
    let emailReStr = '^[-A-Za-z0-9~!$%^&*_=+}{\'?]+(\\.[-a-z0-9~!$%^&*_=+}{\'?]+)*' +
      '@([A-Za-z0-9_][-a-z0-9_]*(\\.[-A-Za-z0-9_]+)*' +
      '\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|school|scot|mobi|[a-zA-Z][a-zA-Z])|' +
      '([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}))(:[0-9]{1,5})?$';
    let emailRe = new RegExp(emailReStr);
    let valid = emailRe.test(emailToCheck);
    return valid;
  }

  const errorClass = 'hide-error';

  return (
    <main className="forgot-password">
      <form>

        <div className="form-group">
          <h2 className={titleClassNames.join(' ')}>Forgot Password</h2>


          <div style={{ marginTop: "10px" }}>
            <label className="font-weight-normal" htmlFor="username">We'll send a recovery link to:</label>
            <input value={email} onChange={handleChangeEmail} name="username" type="text" className="form-control border-width-1px" id="email" onFocus={handleFocus} onBlur={handleBlur} placeholder="Email" />
            {emailTouched && validEmail && <span className="input-valid pull-right glyphicon glyphicon-ok"></span>}
            {emailTouched && !validEmail && email && <span className="input-error pull-right glyphicon glyphicon-remove"></span>}

          </div>

          <div style={{ margin: "10px 0" }}>
            <h4 className={`wcag-err ${errorClass}`}>We didn't recognize that username or password. Try again.</h4>
          </div>

          <div className="text-center">
            <button type="button" className="btn accessible-btn-warning" onClick={sendToEmail}>Send recovery link</button>
            <div className="fix-bottom">
              <Link to="/">Return to log in</Link>
            </div>

          </div>


        </div>

      </form>
    </main>
  );
}

export default ForgotPassword;
