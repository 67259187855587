import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { checkUsernameOrEmail } from '../helpers/auth';
import { SET_ADD_TO_ACCOUNT, SET_EMAIL, SET_USER_ID } from '../reducer';
import { Context } from '../Store';

function Email() {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState({ email: '', focus: true, validEmail: false, touched: false, error: false });

  useEffect(() => {
    emailField.current.focus();
  }, []);

  const emailField = useRef();

  const handleBack = (e) => {
    // For Back button from email prompt, go to Code input field.
    history.push('/code');
  };

  const addToAccount = () => {
    // if the email exists and the code is valid,
    // get the password and use those credentials to login
    // then add the requisite class to that user
    dispatch({ type: SET_ADD_TO_ACCOUNT, payload: true });
    history.push('/login/password');
  }

  const checkEmpty = (focused) => {
    var focus = focused;
    var valid = focus || !focus && state.email && checkEmail();
    var err = !valid ? 'Error: Please enter a valid email address.' : '';
    setState({ ...state, focus, validEmail: valid, err });
  }

  const checkEmail = () => {
    let emailReStr = '^[-A-Za-z0-9~!$%^&*_=+}{\'?]+(\\.[-a-z0-9~!$%^&*_=+}{\'?]+)*' +
      '@([A-Za-z0-9_][-a-z0-9_]*(\\.[-A-Za-z0-9_]+)*' +
      '\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|school|scot|mobi|[a-zA-Z][a-zA-Z])|' +
      '([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}))(:[0-9]{1,5})?$';
    let emailRe = new RegExp(emailReStr);
    let valid = emailRe.test(state.email);
    setState({ ...state, validEmail: valid });
    return valid;
  }

  const updateEmail = (e) => {
    var el = e.currentTarget;
    setState({ ...state, email: el.value });
  }

  const handleNext = (e) => {
    e.preventDefault();
    var email = state.email;
    var isTeacherEdition = appState.isTeacherEdition;
    var createStudentOnFly = appState.createStudentOnFly;
    checkUsernameOrEmail(email).then(
      (response) => {
        dispatch({ type: SET_EMAIL, payload: email });
        response = response.data || response;
        if (response.exists) {
          dispatch({ type: SET_USER_ID, payload: response.user_id });
          setTimeout(() => {
            let el = document.querySelector('h4');
            if (el) el.focus();
            console.log('auth.email.component', el);
          }, 0);
          setState({ ...state, error: true });
        } else {
          // Once upon a time, this was just for teachers, and all that was necessary was to route to create-teacher.
          // Later, however, a need was recognized to be able to create students on the fly, so we're basically using
          // the same path (/auth/users/email) as for creating a new teacher account, only we're instead creating
          // a student account.
          // The createStudentOnFly condition is in auth.code.component.ts.
          if (createStudentOnFly) {
            console.log('create student on fly');
            history.push('/user/create-student');
          } else {
            console.log('create teacher');
            //            this.router.navigate(['/auth/user/create-teacher']);
            history.push('/user/create-teacher');
          }
        }
      },
      (error) => { console.log(error); }
    );
  };


  function selectView() {
    var jsx;
    jsx = (
      <div>
        {!state.error && (<div>
          <div className="form-group">

            <label className="font-weight-normal" htmlFor="email">Email:</label>
            <input
              ref={emailField}
              name="email"
              value={state.email}
              type="text"
              className="form-control border-width-1px"
              id="email"
              onFocus={() => checkEmpty}
              onBlur={() => checkEmpty}
              onChange={updateEmail}
              onKeyUp={checkEmail}
              placeholder="Enter your email" />
            {!state.focus && !state.validEmail && state.touched && <span className="input-error pull-right glyphicon glyphicon-remove"></span>}
            {state.validEmail && state.email && <span className="input-valid pull-right glyphicon glyphicon-ok"></span>}

            {!state.focus && !state.validEmail && state.touched && (<div className="validation-message margin-top-5px">
              <span aria-live="polite">{state.err}; Error: Please enter a valid email address.</span>
            </div>)}

            <div className="fix-bottom">
              <button onClick={handleBack} type="button" className="btn accessible-btn-default">Back</button>
              {(!state.validEmail || !state.email) && <button disabled={true} onClick={handleNext} type="submit" className="btn accessible-btn-primary pull-right">Next</button>}
              {(state.validEmail && state.email) && <button onClick={handleNext} type="submit" className="btn accessible-btn-primary pull-right">Next</button>}
            </div>
          </div>
        </div>)}

        {state.error && (<div>
          <div className="text-center">
            <h4 tabIndex="0" className="wcag-err" aria-live="polite">Error: The email you entered is already in our system.<br />If you'd like to add the course to the email provided, click Continue.</h4>
          </div>
          <div className="fix-bottom">
            <button onClick={handleBack} type="button" className="btn accessible-btn-default">Back</button>
            <button onClick={addToAccount} type="button" className="btn accessible-btn-primary pull-right">Continue</button>
          </div>
        </div>)}

      </div>
    );
    return jsx;
  }

  return (
    <main>
      <form onSubmit={handleNext}>
        {selectView()}
      </form>
    </main>
  );
}

export default Email;
